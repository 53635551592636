/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { calendar, refresh } from "ionicons/icons";
import PieChart from "../../components/PieChart";
import { useHistory } from "react-router";

const HomePage: React.FC = () => {
  const history = useHistory();

  return (
    <div>
      <IonPage>
        <IonHeader>
          <IonToolbar className="customBg">
            <IonButtons slot="start">
              <IonMenuButton className="txtW" />
            </IonButtons>
            <IonTitle>
              <strong className="txtW">SUPER ADMIN</strong>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className="ion-padding">
          <IonRow>
            <div
              className="btnBigSystem"
              onClick={() => {
                history.push("/cd");
              }}
            >
              <img src="assets/cd.png" className="cd" />
              <span>Consultorio Dental</span>
            </div>
          </IonRow>

          <IonRow>
            <div
              className="btnBigSystem"
              onClick={() => {
                history.push("/dd");
              }}
            >
              <img src="assets/dd.png" className="dd" />
              <span>Deposito Dental</span>
            </div>
          </IonRow>

          <IonRow>
            <div
              className="btnBigSystem"
              onClick={() => {
                history.push("/en");
              }}
            >
              <img src="assets/e.png" className="en" />
              <span>Encuestas</span>
            </div>
          </IonRow>

          {/* <iframe src="https://encuestapp.elfuerte.online/"></iframe> */}
        </IonContent>
      </IonPage>
    </div>
  );
};

export default HomePage;
