/* eslint-disable react/react-in-jsx-scope */
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import LoginPage from "./pages/LoginPage";
import { useState } from "react";
import { EventEmmiter } from "./utils/events.js";
import HomePage from "./pages/admin/HomePage";
import $ from "jquery";
import { getToken } from "./utils/utils";
import cdPage from "./pages/cdPage";
import enPage from "./pages/enPage";
import ddPage from "./pages/ddPage";

setupIonicReact();

const App: React.FC = () => {
  const tkn = getToken();
  const [token, setToken] = useState(tkn);
  EventEmmiter.subscribe("UPDATE-ROUTES", (token: any) => {
    setToken(token);
  });

  $(document).ready(function () {
    $(".loader").css("width", "100%");
    // EventEmmiter.dispatch("UPDATE-ENCUESTAS");
    setTimeout(() => {
      // $("#splash").css("transform", "translateY(-100vh)");
      $("#splash").fadeOut();
    }, 2500);
  });

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route exact path="/" component={HomePage} />
            <Route exact path="/cd" component={cdPage} />
            <Route exact path="/dd" component={ddPage} />
            <Route exact path="/en" component={enPage} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
      <LoginPage />
    </IonApp>
  );
};

export default App;
