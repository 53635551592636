/* eslint-disable react/react-in-jsx-scope */
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonSelect,
  IonSelectOption,
  useIonLoading,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
  logOut,
  people,
  person,
  barChartSharp,
  readerSharp,
  personSharp,
  ribbonSharp,
  calendarSharp,
  peopleSharp,
  peopleCircleSharp,
  documentAttachSharp,
  calendarNumberSharp,
  calendarClearSharp,
  cashSharp,
  newspaperSharp,
  statsChartSharp,
  podiumSharp,
  cogSharp,
  gridSharp,
  desktopSharp,
  homeSharp,
  flaskSharp,
  arrowForwardCircleSharp,
  arrowForwardSharp,
} from "ionicons/icons";
import "./Menu.css";
import { useState } from "react";
import { EventEmmiter } from "../utils/events.js";
import { TITLE, roles } from "../utils/constants";
import { getToken, removeToken } from "../utils/utils";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const adminMenu: AppPage[] = [
  {
    title: "Inicio",
    url: "/",
    iosIcon: homeSharp,
    mdIcon: homeSharp,
  },
  {
    title: "Consultorio Dental",
    url: "/cd",
    iosIcon: arrowForwardSharp,
    mdIcon: arrowForwardSharp,
  },
  {
    title: "Depostito Dental",
    url: "/dd",
    iosIcon: arrowForwardSharp,
    mdIcon: arrowForwardSharp,
  },
  {
    title: "Encuestas",
    url: "/en",
    iosIcon: arrowForwardSharp,
    mdIcon: arrowForwardSharp,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  const tkn = getToken();
  const [present, dismiss] = useIonLoading();

  const [token, setToken] = useState(tkn);
  EventEmmiter.subscribe("UPDATE-ROUTES", (token: any) => {
    setToken(token);
  });

  const logOutSystem = () => {
    removeToken();
    present({
      message: "Cerrando Sesión",
      spinner: "circles",
    });
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent className="customBg">
        <IonList id="inbox-list">
          <IonListHeader>SUPER ADMINISTRADOR</IonListHeader>
          <IonNote>
            <strong>{token.name}</strong> [SUPER ADMIN]
          </IonNote>
        </IonList>
      </IonContent>
      <IonContent className="hMenu">
        <IonList>
          <IonList>
            <sub>Menú de Opciones</sub>
          </IonList>
          {adminMenu.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    aria-hidden="true"
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}

          <IonMenuToggle autoHide={false}>
            <IonItem
              className="logout"
              lines="none"
              detail={false}
              onClick={() => logOutSystem()}
            >
              <IonIcon
                aria-hidden="true"
                slot="start"
                ios={logOut}
                md={logOut}
              />
              <IonLabel>Cerrar Sesión</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>

        <IonList id="labels-list">
          <sub>{TITLE} v1.0.1</sub>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
