export interface option {
  title: string;
  value: string | number;
}
export interface question {
  options: option[];
}

export interface surveyResponse {
  DDE_1: string;
}
export const questions = [
  {
    name: "DDE_1",
    question: "Género:",
    header: "🚻 Género:",
    subheader: "",
    message: "Selecciona una opción",
    placeholder: "✍️ Contestar",
    icon: "🚻",
    options: [
      {
        title: "Masculino",
        value: "1",
      },
      {
        title: "Femenino",
        value: "2",
      },
    ],
  },
  {
    name: "DDE_2",
    question: "Edad:",
    header: "👤 Edad:",
    subheader: "",
    message: "Selecciona una opción",
    placeholder: "✍️ Contestar",
    icon: "👤",
    options: [
      {
        title: "18-29 AÑOS",
        value: "1",
      },
      {
        title: "30-40 AÑOS",
        value: "2",
      },
      {
        title: "41-50 AÑOS",
        value: "3",
      },
      {
        title: "51 O MAS AÑOS",
        value: "4",
      },
    ],
  },
  {
    name: "DDE_3",
    question: "Nivel Socio-Económico:",
    header: "💵 Nivel Socio-Económico:",
    subheader: "",
    message: "Selecciona una opción",
    placeholder: "✍️ Contestar",
    icon: "💵",
    options: [
      {
        title: "ALTO",
        value: "1",
      },
      {
        title: "MEDIO",
        value: "2",
      },
      {
        title: "BAJO",
        value: "3",
      },
      {
        title: "ZONA RURAL",
        value: "4",
      },
    ],
  },
  {
    name: "DDE_4",
    question: "Ocupación:",
    header: "💼 Ocupación:",
    subheader: "",
    message: "Selecciona una opción",
    placeholder: "✍️ Contestar",
    icon: "💼",
    options: [
      {
        title: "EMPLEADO",
        value: "1",
      },
      {
        title: "EMPRESARIO",
        value: "2",
      },
      {
        title: "AGRICULTOR",
        value: "3",
      },
      {
        title: "GANADERO",
        value: "4",
      },
      {
        title: "AMA DE CASA",
        value: "5",
      },
      {
        title: "ESTUDIANTE",
        value: "6",
      },
      {
        title: "COMERCIANTE",
        value: "7",
      },
      {
        title: "OTRO",
        value: "8",
      },
    ],
  },
  {
    name: "DDE_5",
    question: "Estudios:",
    header: "📚 Estudios:",
    subheader: "",
    message: "Selecciona una opción",
    placeholder: "✍️ Contestar",
    icon: "📚",
    options: [
      {
        title: "PRIMARIA",
        value: "1",
      },
      {
        title: "SECUNDARIA",
        value: "2",
      },
      {
        title: "PREPARATORIA",
        value: "3",
      },
      {
        title: "UNIVERSIDAD",
        value: "4",
      },
      {
        title: "POST-GRADO",
        value: "5",
      },
      {
        title: "NO ESTUDIO",
        value: "6",
      },
    ],
  },
];
const partidosPoliticos = [
  {
    title: "MORENA",
    value: "1",
  },
  {
    title: "PRI",
    value: "2",
  },
  {
    title: "PAN",
    value: "3",
  },
  {
    title: "PRD",
    value: "4",
  },
  {
    title: "MOVIMIENTO CIUDADANO",
    value: "5",
  },
  {
    title: "PARTIDO SINALOENSE (PAS)",
    value: "6",
  },
  {
    title: "PARTIDO VERDE",
    value: "7",
  },
  {
    title: "NINGUNO",
    value: "8",
  },
  {
    title: "OTRO",
    value: "9",
  },
  {
    title: "NO SABE / NO CONTESTA",
    value: "10",
  },
];
export const surbey = [
  {
    name: "EN_1",
    question:
      "¿Cree usted que el gobierno actual encabezado por Gildardo Leyva Ortega es mejor, igual o peor que el gobierno municipal anterior liderado por Nubia Ramos?",
    header: "Pregunta 1️⃣",
    subheader:
      "¿Cree usted que el gobierno actual encabezado por Gildardo Leyva Ortega es mejor, igual o peor que el gobierno municipal anterior liderado por Nubia Ramos?",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "1️⃣",
    options: [
      {
        title: "MEJOR",
        value: "1",
      },
      {
        title: "IGUAL",
        value: "2",
      },
      {
        title: "PEOR",
        value: "3",
      },
      {
        title: "NO SABE/NO CONTESTA",
        value: "4",
      },
    ],
  },
  {
    name: "EN_2",
    question:
      "En una escala del cero al diez, donde cero es una calificación muy mala y diez es una calificación muy buena, ¿qué puntuación le daría a Gildardo Leyva Ortega por su trabajo como presidente municipal de El Fuerte?",
    header: "Pregunta 2️⃣",
    subheader:
      "En una escala del cero al diez, donde cero es una calificación muy mala y diez es una calificación muy buena, ¿qué puntuación le daría a Gildardo Leyva Ortega por su trabajo como presidente municipal de El Fuerte?",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "2️⃣",
    options: [
      {
        title: "🔟",
        value: "10",
      },
      {
        title: "9️⃣",
        value: "9",
      },
      {
        title: "8️⃣",
        value: "8",
      },
      {
        title: "7️⃣",
        value: "7",
      },
      {
        title: "6️⃣",
        value: "6",
      },
      {
        title: "5️⃣",
        value: "5",
      },
      {
        title: "4️⃣",
        value: "4",
      },
      {
        title: "3️⃣",
        value: "3",
      },
      {
        title: "2️⃣",
        value: "2",
      },
      {
        title: "1️⃣",
        value: "1",
      },
      {
        title: "0️⃣",
        value: "0",
      },
    ],
  },
  {
    question:
      "En su opinión, ¿cómo evalúa a Gildardo Leyva Ortega en las siguientes cualidades? Por favor, seleccione entre: Bueno, Regular o Malo.",
    header: "Pregunta 3️⃣",
    subheader:
      "En su opinión, ¿cómo evalúa a Gildardo Leyva Ortega en las siguientes cualidades? Por favor, seleccione entre: Bueno, Regular o Malo.",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "3️⃣",
    subQuestions: [
      {
        name: "EN_3_1",
        question: "HONESITDAD",
        header: "HONESITDAD",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENO",
            value: "1",
          },
          {
            title: "REGULAR",
            value: "2",
          },
          {
            title: "MALO",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_3_2",
        question: "TRABAJO",
        header: "TRABAJO",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENO",
            value: "1",
          },
          {
            title: "REGULAR",
            value: "2",
          },
          {
            title: "MALO",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_3_3",
        question: "AYUDA A LA GENTE",
        header: "AYUDA A LA GENTE",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENO",
            value: "1",
          },
          {
            title: "REGULAR",
            value: "2",
          },
          {
            title: "MALO",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_3_4",
        question: "CAPACIDAD PARA GOBERNAR ",
        header: "CAPACIDAD PARA GOBERNAR ",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENO",
            value: "1",
          },
          {
            title: "REGULAR",
            value: "2",
          },
          {
            title: "MALO",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_3_5",
        question: "CAPACIDAD PARA RESOLVER PROBLEMAS",
        header: "CAPACIDAD PARA RESOLVER PROBLEMAS",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENO",
            value: "1",
          },
          {
            title: "REGULAR",
            value: "2",
          },
          {
            title: "MALO",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
    ],
  },
  {
    question:
      "Por favor, dígame su opinión sobre el Ayuntamiento de El Fuerte en relación a los siguientes temas que voy a mencionar:",
    header: "Pregunta 4️⃣",
    subheader:
      "Por favor, dígame su opinión sobre el Ayuntamiento de El Fuerte en relación a los siguientes temas que voy a mencionar:",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "4️⃣",
    subQuestions: [
      {
        name: "EN_4_1",
        question: "AGUA POTABLE Y DRENAJE",
        header: "AGUA POTABLE Y DRENAJE",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_2",
        question: "RECOLECCION DE BASURA ",
        header: "RECOLECCION DE BASURA ",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_3",
        question: "IMPULSO A LA VIVIENDA",
        header: "IMPULSO A LA VIVIENDA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_4",
        question: "ALUMBRADO PUBLICO ",
        header: "ALUMBRADO PUBLICO ",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_5",
        question: "SEGURIDAD PUBLICA ",
        header: "SEGURIDAD PUBLICA ",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_6",
        question: "CONSTRUCCION DE OBRA PUBLICA",
        header: "CONSTRUCCION DE OBRA PUBLICA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_7",
        question: "IMPULSO Y APOYO AL DEPORTE",
        header: "IMPULSO Y APOYO AL DEPORTE",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_8",
        question: "GENERACION DE EMPLEOS",
        header: "GENERACION DE EMPLEOS",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_9",
        question: "PAVIMENTACION Y MANTENIMIENTO DE CAMINOS",
        header: "PAVIMENTACION Y MANTENIMIENTO DE CAMINOS",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_10",
        question: "INFRAESTRUCTURA EDUCATIVA",
        header: "INFRAESTRUCTURA EDUCATIVA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_11",
        question: "ATENCION A PUEBLOS Y COMUNIDADES",
        header: "ATENCION A PUEBLOS Y COMUNIDADES",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
      {
        name: "EN_4_12",
        question: "PROMOCION A LA ACTIVIDAD TURISTICA",
        header: "PROMOCION A LA ACTIVIDAD TURISTICA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NS/NC",
            value: "4",
          },
        ],
      },
    ],
  },
  {
    question:
      "¿Está familiarizado con los nombres que le voy a mencionar? Por favor, indique si los ha escuchado o conoce de ellos.",
    header: "Pregunta 5️⃣",
    subheader:
      "¿Está familiarizado con los nombres que le voy a mencionar? Por favor, indique si los ha escuchado o conoce de ellos.",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "5️⃣",
    subQuestions: [
      {
        name: "EN_5_1",
        question: "GILDARDO LEYVA ORTEGA",
        header: "GILDARDO LEYVA ORTEGA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_2",
        question: "LUIS LUGO GAXIOLA",
        header: "LUIS LUGO GAXIOLA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_3",
        question: "VICENTE PICO",
        header: "VICENTE PICO",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_4",
        question: "MARIBEL VEGA",
        header: "MARIBEL VEGA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_5",
        question: "BERTHA ALICIA LIMON",
        header: "BERTHA ALICIA LIMON",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_6",
        question: "SANDRA HAM",
        header: "SANDRA HAM",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_7",
        question: "CARLOS SARMIENTO",
        header: "CARLOS SARMIENTO",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_8",
        question: "JAIRO LEYVA",
        header: "JAIRO LEYVA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_9",
        question: "JOSE MARIA FLORES",
        header: "JOSE MARIA FLORES",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_10",
        question: "MAYRA OSORIO",
        header: "MAYRA OSORIO",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_11",
        question: "BRYAN GASTELUM",
        header: "BRYAN GASTELUM",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_12",
        question: "LEONEL VEA",
        header: "LEONEL VEA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_13",
        question: "ELEAZAR RUBIO",
        header: "ELEAZAR RUBIO",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
      {
        name: "EN_5_14",
        question: "ANTONIO COTA",
        header: "ANTONIO COTA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "SI LO CONOCE",
            value: "1",
          },
          {
            title: "NO LO CONOCE",
            value: "2",
          },
          {
            title: "NS/NC",
            value: "3",
          },
        ],
      },
    ],
  },
  {
    question:
      "¿Qué opinión tienes de las personas que voy a mencionarte, basado en lo que sabes o has escuchado sobre ellas? ¿Crees que son buenas, regulares o malas?",
    header: "Pregunta 6️⃣",
    subheader:
      "¿Qué opinión tienes de las personas que voy a mencionarte, basado en lo que sabes o has escuchado sobre ellas? ¿Crees que son buenas, regulares o malas?",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "6️⃣",
    subQuestions: [
      {
        name: "EN_6_1",
        question: "GILDARDO LEYVA ORTEGA",
        header: "GILDARDO LEYVA ORTEGA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_2",
        question: "LUIS LUGO GAXIOLA",
        header: "LUIS LUGO GAXIOLA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_3",
        question: "VICENTE PICO",
        header: "VICENTE PICO",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_4",
        question: "MARIBEL VEGA",
        header: "MARIBEL VEGA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_5",
        question: "BERTHA ALICIA LIMON",
        header: "BERTHA ALICIA LIMON",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_6",
        question: "SANDRA HAM",
        header: "SANDRA HAM",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_7",
        question: "CARLOS SARMIENTO",
        header: "CARLOS SARMIENTO",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_8",
        question: "JAIRO LEYVA",
        header: "JAIRO LEYVA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_9",
        question: "JOSE MARIA FLORES",
        header: "JOSE MARIA FLORES",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_10",
        question: "MAYRA OSORIO",
        header: "MAYRA OSORIO",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_11",
        question: "BRYAN GASTELUM",
        header: "BRYAN GASTELUM",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_12",
        question: "LEONEL VEA",
        header: "LEONEL VEA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_13",
        question: "ELEAZAR RUBIO",
        header: "ELEAZAR RUBIO",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
      {
        name: "EN_6_14",
        question: "ANTONIO COTA",
        header: "ANTONIO COTA",
        subheader: "",
        message: "Selecciona una respuesta",
        placeholder: "✍️ Contestar",
        icon: "",
        options: [
          {
            title: "BUENA OPINION",
            value: "1",
          },
          {
            title: "REGULAR OPINION",
            value: "2",
          },
          {
            title: "MALA OPINION",
            value: "3",
          },
          {
            title: "NO SABE/ NO CONTESTA",
            value: "4",
          },
        ],
      },
    ],
  },
  {
    name: "EN_7",
    question:
      "¿Cuál es el partido político con el que te identificas más o con el que te sientes más cercano?",
    header: "Pregunta 7️⃣",
    subheader:
      "¿Cuál es el partido político con el que te identificas más o con el que te sientes más cercano?",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "7️⃣",
    options: [...partidosPoliticos],
  },
  {
    name: "EN_8",
    question:
      "Si hoy fuera la elección para presidente de México, ¿por cuál partido político votarías? (mencionar todos los partidos)",
    header: "Pregunta 8️⃣",
    subheader:
      "Si hoy fuera la elección para presidente de México, ¿por cuál partido político votarías? (mencionar todos los partidos)",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "8️⃣",
    options: [...partidosPoliticos],
  },
  {
    name: "EN_9",
    question:
      "De los nombres que te voy a mencionar, ¿a quién prefieres como candidato de MORENA para la presidencia municipal de El Fuerte?",
    header: "Pregunta 9️⃣",
    subheader:
      "De los nombres que te voy a mencionar, ¿a quién prefieres como candidato de MORENA para la presidencia municipal de El Fuerte?",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "9️⃣",
    options: [
      {
        title: "GILDARDO LEYVA ORTEGA",
        value: "1",
      },
      {
        title: "JOSE MARIA FLORES",
        value: "2",
      },
      {
        title: "SANDRA HAM",
        value: "3",
      },
      {
        title: "MAYRA OSORIO",
        value: "4",
      },
      {
        title: "BRYAN GASTELUM",
        value: "5",
      },
      {
        title: "NINGUNO",
        value: "6",
      },
      {
        title: "OTRO",
        value: "7",
      },
      {
        title: "NO SABE/ NO CONTESTA",
        value: "8",
      },
    ],
  },
  {
    name: "EN_10",
    question:
      "De los nombres que te voy a mencionar, ¿a quién prefieres como candidato de la alianza PRI-PAN-PRD para la presidencia municipal de El Fuerte?",
    header: "Pregunta 🔟",
    subheader:
      "De los nombres que te voy a mencionar, ¿a quién prefieres como candidato de la alianza PRI-PAN-PRD para la presidencia municipal de El Fuerte?",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "🔟",
    options: [
      {
        title: "JAIRO LEYVA",
        value: "1",
      },
      {
        title: "MARIBEL VEGA",
        value: "2",
      },
      {
        title: "LUIS LUGO GAXIOLA",
        value: "3",
      },
      {
        title: "VICENTE PICO",
        value: "4",
      },
      {
        title: "ELEAZAR RUBIO",
        value: "5",
      },
      {
        title: "NINGUNO",
        value: "6",
      },
      {
        title: "OTRO",
        value: "7",
      },
      {
        title: "NO SABE/ NO CONTESTA",
        value: "8",
      },
    ],
  },
  {
    name: "EN_11",
    question:
      "Si hoy fuera la elección para presidente municipal de El Fuerte, ¿por cuál partido político votarías? (mencionar todos los partidos)",
    header: "Pregunta 1️⃣1️⃣",
    subheader:
      "Si hoy fuera la elección para presidente municipal de El Fuerte, ¿por cuál partido político votarías? (mencionar todos los partidos)",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "1️⃣1️⃣",
    options: [...partidosPoliticos],
  },
  {
    name: "EN_12",
    question:
      "Si hoy fuera la elección para presidente municipal de El Fuerte, ¿por cuál partido o alianza de partidos votarías?",
    header: "Pregunta 1️⃣2️⃣",
    subheader:
      "Si hoy fuera la elección para presidente municipal de El Fuerte, ¿por cuál partido o alianza de partidos votarías?",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "1️⃣2️⃣",
    options: [
      {
        title: "ALIANZA MORENA-PARTIDO VERDE Y PARTIDO DEL TRABAJO",
        value: "1",
      },
      {
        title: "ALIANZA PRI-PAN-PRD",
        value: "2",
      },
      {
        title: "PAS",
        value: "3",
      },
      {
        title: "MOVIMIENTO CIUDADANO",
        value: "4",
      },
      {
        title: "NINGUNO",
        value: "5",
      },
      {
        title: "NO SABE/ NO CONTESTA",
        value: "6",
      },
    ],
  },
  {
    name: "EN_13",
    question:
      "Si hoy fuera la elección para presidente municipal de El Fuerte y los candidatos fueran (mencionar todos los nombres), ¿por quién votarías?",
    header: "Pregunta 1️⃣3️⃣",
    subheader:
      "Si hoy fuera la elección para presidente municipal de El Fuerte y los candidatos fueran (mencionar todos los nombres), ¿por quién votarías?",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "1️⃣3️⃣",
    options: [
      {
        title: "GILDARDO LEYVA ORTEGA, POR MORENA",
        value: "1",
      },
      {
        title: "LUIS LUGO GAXIOLA, POR LA ALIANZA PRI-PAN-PRD",
        value: "2",
      },
      {
        title: "ANTONIO COTA, POR MOVIMIENTO CIUDADANO (MC)",
        value: "3",
      },
      {
        title: "VICENTE PICO, POR EL PARTIDO SINALOENSE (PAS)",
        value: "4",
      },
      {
        title: "NINGUNO",
        value: "5",
      },
      {
        title: "NO SABE/ NO CONTESTA",
        value: "6",
      },
    ],
  },
  {
    name: "EN_14",
    question:
      "Si hoy fuera la elección para presidente municipal de El Fuerte y los candidatos fueran (mencionar todos los nombres), ¿por quién votarías?",
    header: "Pregunta 1️⃣4️⃣",
    subheader:
      "Si hoy fuera la elección para presidente municipal de El Fuerte y los candidatos fueran (mencionar todos los nombres), ¿por quién votarías?",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "1️⃣4️⃣",
    options: [
      {
        title: "VICENTE PICO, POR EL PARTIDO SINALOENSE (PAS)",
        value: "1",
      },
      {
        title: "SANDRA HAM, POR MORENA",
        value: "2",
      },
      {
        title: "ELEAZAR RUBIO, POR LA ALIANZA PRI-PAN-PRD",
        value: "3",
      },
      {
        title: "JAIRO LEYVA, POR MOVIMIENTO CIUDADANO (MC)",
        value: "4",
      },
      {
        title: "NINGUNO",
        value: "5",
      },
      {
        title: "NO SABE/ NO CONTESTA",
        value: "6",
      },
    ],
  },
  {
    name: "EN_15",
    question:
      "En la elección del próximo año, MORENA estaría llamando a votar parejo en las cinco boletas electorales. ¿Tú piensas votar? (mencionar opciones de respuesta)",
    header: "Pregunta 1️⃣5️⃣",
    subheader:
      "En la elección del próximo año, MORENA estaría llamando a votar parejo en las cinco boletas electorales. ¿Tú piensas votar? (mencionar opciones de respuesta)",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "1️⃣5️⃣",
    options: [
      {
        title: "VOTARE PAREJO TODO POR MORENA",
        value: "1",
      },
      {
        title: "VOTARE CRUZADO",
        value: "2",
      },
      {
        title: "NO SABE/ NO CONTESTA",
        value: "3",
      },
    ],
  },
  {
    name: "EN_16",
    question:
      "Independientemente del partido por el que piensas votar, ¿cuál partido crees que ganará la presidencia municipal de El Fuerte en la elección del próximo año?",
    header: "Pregunta 1️⃣6️⃣",
    subheader:
      "Independientemente del partido por el que piensas votar, ¿cuál partido crees que ganará la presidencia municipal de El Fuerte en la elección del próximo año?",
    message: "Selecciona una respuesta",
    placeholder: "✍️ Contestar",
    icon: "1️⃣6️⃣",
    options: [...partidosPoliticos],
  },
];
