import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyAQO4UuSKe44_5W4WukluUlq9WmRbCxMAU",
  authDomain: "consultorio-brian.firebaseapp.com",
  projectId: "consultorio-brian",
  storageBucket: "consultorio-brian.appspot.com",
  messagingSenderId: "257437930396",
  appId: "1:257437930396:web:28951245eaa214d6e52e59",
  measurementId: "G-M03E8MB8QJ",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
