/* eslint-disable @typescript-eslint/no-inferrable-types */
import $ from "jquery";
import { TOKEN_NAME } from "./constants";
import { questions } from "./questions";
import {
  collection,
  addDoc,
  collectionGroup,
  getDocs,
  orderBy,
  query,
  where,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "./firebaseConfig";

export interface iToken {
  id: string;
  name: string;
  user: string;
  password: string;
  rol: string;
  createdAt: string;
}
export const getToken = (): iToken => {
  const token = localStorage.getItem(TOKEN_NAME);
  if (token != null) {
    const tokenParsed = JSON.parse(token);
    return tokenParsed as iToken;
  } else {
    return {
      id: "",
      name: "",
      user: "",
      password: "",
      rol: "",
      createdAt: "",
    };
  }
};
export const setToken = (token: object) => {
  localStorage.setItem(TOKEN_NAME, JSON.stringify(token));
};
export const removeToken = () => {
  localStorage.removeItem(TOKEN_NAME);
};

export const now = () => {
  return (
    new Date().toISOString().slice(0, 10) +
    " " +
    new Date().toLocaleTimeString("en-GB")
  );
};

export const openLogin = () => {
  $("#LOGIN-USER").val("");
  $("#LOGIN-PASSWORD").val("");
  $("#LOGIN-PAGE").css("transform", "translateY(0vh)");
};
export const closeLogin = () => {
  $("#LOGIN-PAGE").css("transform", "translateY(100vh)");
};

export const getValueDoc = (value: string, position: number) => {
  const val = questions[position].options.find((o) => o.value === value);
  if (val) {
    return val.title;
  } else {
    return "";
  }
};

export const formatearFecha = (fecha: string): string => {
  const fechaObj: Date = new Date(fecha);
  const dia: number = fechaObj.getDate();
  const mes: number = fechaObj.getMonth() + 1; // en JavaScript los meses empiezan desde 0 (enero) hasta 11 (diciembre)
  const anio: number = fechaObj.getFullYear();
  const horas: number = fechaObj.getHours();
  const minutos: number = fechaObj.getMinutes();
  const formato12h: string = horas >= 12 ? "PM" : "AM";
  const hora12h: number = horas % 12 || 12; // convierte horas en formato 12 horas
  const horaStr: string = hora12h < 10 ? `0${hora12h}` : `${hora12h}`;
  const minutosStr: string = minutos < 10 ? `0${minutos}` : `${minutos}`;
  const fechaFormateada: string = `${dia}/${mes}/${anio} ${horaStr}:${minutosStr} ${formato12h}`;
  return fechaFormateada;
};

export const setValue = (name: string, response: any) => {
  const val = response[name];
  return response[name];
};

export function solicitarPermisoUbicacion(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(
        new Error("La geolocalización no está disponible en este navegador.")
      );
    } else {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "granted") {
            resolve(true);
          } else if (permissionStatus.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              () => resolve(true),
              () => resolve(false)
            );
          } else {
            resolve(false);
          }
        })
        .catch(() =>
          reject(
            new Error("Ocurrió un error al solicitar permiso de ubicación.")
          )
        );
    }
  });
}

export function obtenerLatitudLongitud(): Promise<{
  latitud: number;
  longitud: number;
} | null> {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(
        new Error("La geolocalización no está disponible en este navegador.")
      );
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitud: position.coords.latitude,
            longitud: position.coords.longitude,
          });
        },
        () => {
          resolve(null);
        }
      );
    }
  });
}

export const isAviableUser = async () => {
  const token = getToken();
  if (token.rol === "DOCTOR") {
    const user = (await getDoc(doc(db, "users", token.id))) as any;
    if (user.data().status === 0) {
      alert("DESHABILITADO");

      removeToken();
      window.location.href = "/";
    }
  }
};
