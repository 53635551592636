/* eslint-disable react/react-in-jsx-scope */
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
} from "@ionic/react";
import { useEffect } from "react";
import { getToken } from "../utils/utils";

const cdPage: React.FC = () => {
  const token = getToken();

  //   useEffect(() => {});

  return (
    <IonPage id="cdPage">
      <IonHeader>
        <IonToolbar className="customBg">
          <IonButtons slot="start">
            <IonMenuButton className="txtW" />
          </IonButtons>
          <IonTitle>
            <strong className="txtW">Super Admin - Consultorio Dental</strong>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="cIframe">
        <iframe src="https://consultorio.elfuerte.online/"></iframe>
      </IonContent>
    </IonPage>
  );
};

export default cdPage;
